<template>
<default-container>
    <q-card slot="page-content" class="container col-md-6 p-0 mb-3 mb-md-4">
        <b-row class="full-width">
            <b-col sm="12" md="12" class="p-3 p-md-4">
                <q-item class="full-width p-0 mb-2 bg-white">
                    <q-item-section>
                        <div class="text-h6">{{ translate('user_settings') }}</div>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn v-if="!$q.screen.lt.sm" @click="showEditSettingsPanel" type="button" no-caps color="primary">
                            <i class="fas fa-edit mr-1"></i> {{ translate('edit') }}
                        </q-btn>
                        <q-btn v-if="$q.screen.lt.sm" flat round no-caps color="primary" type="button" @click="showEditSettingsPanel">
                            <i class="fas fa-edit"></i>
                        </q-btn>
                    </q-item-section>
                </q-item>
                <q-separator />
                <q-item class="full-width pl-0 pr-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('receive_emails') }}</span>
                        {{ settings.receive_emails ? settings.receive_emails : translate('not_seted') }}
                    </q-item-section>
                </q-item>
                <q-item class="full-width pl-0 pr-0">
                    <q-item-section>
                        <span class="text-muted">{{ translate('notifications_language') }}</span>
                        {{ settings.notifications_language ? translate('lang_' + settings.notifications_language) : translate('not_seted') }}
                        <small v-if="!settings.notifications_language">{{ translate('default_language') }}: {{ $appSettings.lang }}</small>
                    </q-item-section>
                </q-item>
            </b-col>
        </b-row>
    </q-card>
</default-container>
</template>

<script>
import DefaultContainer from '../../../containers/DefaultContainer'
import EditUserSettings from './forms/EditUserSettings'
import { eventBus } from '../../../main'

export default {
    name: 'UserSettings',
    components: {
        'default-container': DefaultContainer
    },
    data: function () {
        return {
            settings: {
                receive_emails: null,
                notifications_language: null
            }
        }
    },
    created: function () {
        this.getSettings()
    },
    mounted: function () {
        eventBus.$on('update_user_settings', () => {
            this.getSettings()
        })
    },
    methods: {
        getSettings: function () {
            var url = baseUrl + 'users/preferences'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers
                })
                .then(response => {
                    if (response.data.status) {
                        this.settings = response.data.user_preferences
                        if (response.data.user_preferences.receive_emails === '1') {
                          this.settings.receive_emails = this.translate('yes')
                        }
                        if (response.data.user_preferences.receive_emails === '0') {
                          this.settings.receive_emails = this.translate('no')
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showEditSettingsPanel: function () {
            const panelInstance = this.$showPanel({
                component: EditUserSettings,
            })
        },
    }
}
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
    width: 100% !important;
}
</style>
