<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1 mb-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_user_settings') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form>
                    <b-list-group-item>
                        <q-checkbox @input="editSetting('receive_emails', settings.receive_emails)" flat :label="translate('receive_emails')" dense outlined id="receive_emails" v-model="settings.receive_emails" />
                    </b-list-group-item>
                    <b-list-group-item class="pl-1 pr-1">
                        <q-select map-options @input="editSetting('notifications_language', settings.notifications_language.value)" dense v-model="settings.notifications_language" :options="language_options" :label="translate('select_language')" :val="settings.notifications_language" />
                        <small v-if="!settings.notifications_language" class="pl-3">{{ translate('default_language') }}: {{ $appSettings.lang }}</small>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../../main'

export default {
    name: 'EditUserSettings',
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            settings: {
                receive_emails: false,
                notifications_language: null,
            },
            msg: null,
            language_options: [{
                    value: 'en',
                    label: 'EN'
                },
                {
                    value: 'ro',
                    label: 'RO'
                }
            ],
            status: false
        }
    },
    created: function () {
        this.getUserSettings()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getUserSettings: function () {
            var url = baseUrl + 'users/preferences'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers
                })
                .then(response => {
                    if (response.data.status && !_.isEmpty(response.data.user_preferences)) {
                        this.settings = response.data.user_preferences
                        
                        // Set the value for receive_emails.
                        if (response.data.user_preferences.receive_emails === '1') {
                            this.settings.receive_emails = true
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editSetting: function (preference_name, preference_value, msg) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var url = baseUrl + 'users/preferences'
            var data = {
                'preference_name': preference_name,
                'preference_value': preference_value,
            }

            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + resonse.data.key : ''
                    var message = 'edit_user_settings_' + this.msg + key + '_' + preference_name
                    var translated_message = this.translate(message)
                    
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.status = true
                        eventBus.$emit('update_user_settings')
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                }).then(response => {
                    if (this.settings.notifications_language === null) {
                        this.settings.notifications_language = {value: this.$appSettings.lang, label: this.$appSettings.lang.toUpperCase()}
                        this.editSetting('notifications_language', this.$appSettings.lang)
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {
                status: this.status
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.position-absolute {
    &.right {
        right: 0;
    }

    &.top {
        top: 0;
    }
}

.close-panel {
    &.position-absolute {
        padding: 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, .1);
    }
}
</style>
